import cornflowerBlue from './assets/cornflower-blue.png';
import darkBeigePebbles from './assets/dark-beige-pebbles.png';
import darkPear from './assets/dark-pear.png';
import darkTealCyan from './assets/dark-teal-cyan.jpg';
import dustyPlum from './assets/dusty-plum.png';
import goldenYellowDots from './assets/golden-yellow-dots.png';
import lavenderWaves from './assets/lavender-waves.png';
import miniWhales from './assets/mini-whales.png';
import streamOfFish from './assets/streams-of-fish.png';
import podOfWhales from './assets/pod-of-whales.png';
import abstractMountain from './assets/abstract-mountain.png';
import abstractOcean from './assets/abstract-ocean.png';
import tanStripe from './assets/tan-stripe.png';

export const products = [
	{
		name: 'Cornflower Blue',
		imgSrc: cornflowerBlue,
		description: `Soft Breathable Cotton Knit Neckie, approx size 8 in High, and 9.75 in wide`,
		material: `95% Cotton Knit, 5% Spandex`,
	},
	{
		name: 'Dark Beige Rose w/ Pebbles',
		imgSrc: darkBeigePebbles,
		description: `Soft Breathable Cotton Knit Neckie, approx size 8 in High, and 9.75 in wide`,
		material: `95% Cotton Knit, 5% Spandex`,
	},
	{
		name: 'Dark Pear',
		imgSrc: darkPear,
		description: `Soft Breathable Cotton Knit Neckie, approx size 8 in High, and 9.75 in wide`,
		material: `95% Cotton Knit, 5% Spandex`,
	},
	{
		name: 'Dark Teal Cyan',
		imgSrc: darkTealCyan,
		description: `Soft Breathable Cotton Knit Neckie, approx size 8 in High, and 9.75 in wide`,
		material: `95% Cotton Knit, 5% Spandex`,
	},
	{
		name: 'Dusty Plum',
		imgSrc: dustyPlum,
		description: `Soft Breathable Cotton Knit Neckie, approx size 8 in High, and 9.75 in wide`,
		material: `95% Cotton Knit, 5% Spandex`,
	},
	{
		name: 'Golden Poppy w/ Snow',
		imgSrc: goldenYellowDots,
		description: `Soft Breathable Cotton Knit Neckie, approx size 8 in High, and 9.75 in wide`,
		material: `95% Cotton Knit, 5% Spandex`,
	},
	{
		name: 'Lavender Waves',
		imgSrc: lavenderWaves,
		description: `Soft Breathable Cotton Knit Neckie, approx size 8 in High, and 9.75 in wide`,
		material: `93% Cotton Knit, 7% Spandex`,
	},
	{
		name: 'Streams of Fish',
		imgSrc: streamOfFish,
		description: `Soft Breathable Cotton Knit Neckie, approx size 8 in High, and 9.75 in wide`,
		material: `95% Cotton Knit, 5% Spandex`,
	},
	{
		name: 'Mini Whales',
		imgSrc: miniWhales,
		description: `Soft Breathable Cotton Knit Neckie, approx size 8 in High, and 9.75 in wide`,
		material: `95% Cotton Knit, 5% Spandex`,
	},
	{
		name: 'Pod of Whales',
		imgSrc: podOfWhales,
		description: `Soft Breathable Cotton Knit Neckie, approx size 8 in High, and 9.75 in wide`,
		material: `95% Polyester, 5% Spandex`,
	},
	{
		name: 'Abstract Mountain',
		imgSrc: abstractMountain,
		description: `Soft Breathable Cotton Knit Neckie, approx size 8 in High, and 9.75 in wide`,
		material: `93% Polyester, 7% Spandex`,
	},
	{
		name: 'Abstract Ocean',
		imgSrc: abstractOcean,
		description: `Soft Breathable Cotton Knit Neckie, approx size 8 in High, and 9.75 in wide`,
		material: `100% Cotton Knit`,
	},
	{
		name: 'Tan Stripes',
		imgSrc: tanStripe,
		description: `Soft Breathable Cotton Knit Neckie, approx size 8 in High, and 9.75 in wide`,
		material: `93% Polyester, 7% Spandex`,
	},
];
